<template>
  <v-dialog
      persistent max-width="50%" min-width="200px"
      :value="value"
      @input="onInput"
  >
    <template #activator="{on, attrs}">
      <v-btn
        block
        color="primary"
        v-bind="attrs"
        @click="$emit('activate')"
      >{{ activatorLabel }}</v-btn>
    </template>
    <v-card>
      <v-card-title><v-icon>mdi-information-outline</v-icon>{{ $t('LBL_INFO') }}️</v-card-title>
      <v-card-subtitle><br/>{{ dialogTitle }}</v-card-subtitle>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="$emit('cancel')" color="secondary">{{ $t('LBL_BTN_CANCEL') }}</v-btn>
        <v-btn @click="$emit('confirm')" color="primary">{{ $t('LBL_BTN_CONFIRM') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CustomDialog",
  props: [
      "value",
      "activatorLabel",
      "dialogTitle",
  ],
  methods: {
    onInput(event) {
      this.$emit('input', event);
    }
  }
}
</script>

<i18n>
{
  "en": {
    "LBL_INFO": "Information",
    "LBL_BTN_CANCEL": "Cancel",
    "LBL_BTN_CONFIRM": "Confirm"
  }
}
</i18n>
